import ApiService from "./ApiService";

export const exportXlsxItensAdicionais = async (codConcessionaria:any, nomeArquivo: string): Promise<any> => {

    // const codConcessionaria:any = "353";
    const { data } = await ApiService.postXlsx(`/itensadicionais/exportar/`+codConcessionaria, {} );
        if(data){
          const blob = new Blob([(data)], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            console.log(blob,'blob');

            const link = document.createElement('a');
            if (link.download !== undefined) {
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', nomeArquivo+".xlsx");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            };
        };
    return data;
};


export const exportXlsxItensAdicionaisModeloKit = async (nomeArquivo: string): Promise<any> => {

  // const codConcessionaria:any = "353";
  const { data } = await ApiService.postXlsx(`/itensadicionais/exportar-modelo`);
      if(data){
        const blob = new Blob([(data)], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
          console.log(blob,'blob');

          const link = document.createElement('a');
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', nomeArquivo+".xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
      };
  return data;
};


export const importExcel = async (codConcessionaria: number, payload) => {
  const {data} = await ApiService.post(`/itensadicionais/importar/${codConcessionaria}`,payload)
  return data
}



export default { exportXlsxItensAdicionais, importExcel , exportXlsxItensAdicionaisModeloKit};