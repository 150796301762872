
import { ref, onMounted, computed } from "vue";
import { Modal } from "bootstrap";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import usePDF from "@/composables/Pdf";
import useEmitter from "@/composables/Emmiter";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";
import AuthService from "@/services/AuthService";
import { getDescricao } from "@/services/CrudService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {exportXlsxItensAdicionais, importExcel, exportXlsxItensAdicionaisModeloKit} from '@/services/Cardapio';
import {useRouter, useRoute} from 'vue-router';

export interface CodigosDescricao {
  codPersonalizacao: number;
  nomePersonalizacao: string;
}

export default {
  name: "export-modal",
  components: {
    QuillEditor,
  },
  setup(props) {
    const store = useStore();
    const emitter = useEmitter();
    const importExcelView = ref(true);
    const concessionariaInfo: any = ref({});
    const concessionariaSelectedInfo = store.getters.concessionariaSelectedInfo;
    const concessionariaSelected = ref();
    const userInfo = AuthService.getUsuarioAuth();
    const nomeUser = userInfo.nome;
    const { exportBlob } = usePDF();
    const disabledButtonExportar = ref(false);
    const loadingEmail = ref(false);
    const loadingExportPdf = ref(false);
    const loadingExportXLSX = ref(false);
    const showClose = ref(false);
    const showExportPdf = ref(true);
    const showExportXLSX = ref(true);
    const modalExport = ref();
    const modalShareEmail = ref();
    const contentResponse = ref();
    const contentEmail = ref("");
    const produtos = ref({});
    const diasSegurancaEstoque = ref();
    const { showTimeAlert, showConfirmAlert } = useAlert();
    const uploadRef = ref();
    const codigosDescricao = ref();
    const searchDescrição = ref();
    const modalCodigosDescricao = ref();
    const route = useRoute();
    function reset() {
      showExportXLSX.value = true;
      showExportPdf.value = true;
      contentResponse.value = "";
    }

    const useUpload = (formData) => {
      document.body.classList.add("page-loading");
      importExcel(concessionariaSelected.value, formData)
        .then((r) => {
          document.body.classList.remove("page-loading");

          if (r.length > 0) {
            console.log("entrou no r");
            const error = r?.map((erro) => {
              return `Error  na linha ${erro.row} : ${erro.errors} <br>`;
            });

            showConfirmAlert("", "error", " ", "OK", "center");

            const divMessage = document.querySelector("#swal2-content");
            const modal = document.querySelector(".swal2-modal");
            modal?.classList.add("widthModal");

            //injeta os erros direto no html
            if (divMessage) divMessage.innerHTML = error.toString().replace(/,/g," ");
            uploadRef.value.clearFiles(); // limpar o campo do upload

            return;
          }

          emitter.emit("importExcel");
          showTimeAlert("Planilha importada com sucesso.");
          importExcelView.value = true;
        })
        .catch((r) => {
          console.log(r);
          showTimeAlert("Não foi possível completar a solicitação", "error");
          uploadRef.value.clearFiles(); // limpar o campo do upload
          document.body.classList.remove("page-loading");
        });
    };

    const avisoConfirmacaoImport = (NamefileImport) => {
      const useSwal = Swal.fire({
        title: "Confirmação de importação da planilha",
        text:
          "Todos os itens da planilha substituirão os itens já existentes. Você tem certeza de que deseja fazer isso?",
        showCancelButton: true,
        confirmButtonText: "Importar Planilha",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        reverseButtons: true,
      });

      const divMessage = document.querySelector(".swal2-title");
      divMessage?.setAttribute("style", "display: block");
      const span = document.createElement("span");
      span.classList.add("name-file-upload");
      span.innerHTML = `<br><br> ${NamefileImport}`;
      // span.appendChild(textSpan);
      divMessage?.appendChild(span);
      return useSwal;
    };

    function handleSuccess(response, file, fileList) {
      const formData = new FormData();
      formData.append("file", file[0].raw);
      if (!response.name.includes(".xlsx")) {
        showTimeAlert("Formato de arquivo invalido", "error");
        uploadRef.value.clearFiles(); // limpar o campo do upload
        return;
      }
      avisoConfirmacaoImport(response.name).then((result) => {
        if (result.isConfirmed) {
          useUpload(formData);
        } else {
          uploadRef.value.clearFiles(); // limpar o campo do upload
        }
      });
    }

    store.watch(
      () => store.getters.concessionariaSelectedInfo,
      () => {
        concessionariaInfo.value = Object.assign(
          {},
          store.getters.concessionariaSelectedInfo
        );
      },
      { deep: true }
    );


    const showImportView = () => {
      importExcelView.value = false;
      getDescricoes();
    };

    async function exportarRelatorio(exportFull = false) {
      disabledButtonExportar.value = true;
      const distribuidor = distribuidorConfig[0];

      let response;
      try {
        if (exportFull) {
          emitter.emit("geraPDF");
          loadingExportPdf.value = true;
          const dataValue = {
            produtos: produtos.value,
            concessionariaSelectedInfo: {
              nomeConcessionaria: concessionariaSelectedInfo.nomeFantasia,
              codAtria: concessionariaSelectedInfo.codAtria,
              diasSegurancaEstoque: diasSegurancaEstoque.value,
            },
            nomeUser,
            distribuidor: distribuidor.distribuidor,
          };
      //    response = await estoquePdf(dataValue);
          loadingExportPdf.value = false;
          showExportXLSX.value = false;
          showExportPdf.value = true;
        } else {
          showExportPdf.value = false;
        }

        contentResponse.value = response;
      } catch (e) {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      } finally {
        loadingExportPdf.value = false;
        loadingExportXLSX.value = false;
        disabledButtonExportar.value = false;
      }
    }

    function exportarItensAdicionais() {
      var concessionariaInfo = store.getters.concessionariaSelectedInfo;
      var nomeArquivo = concessionariaInfo.nomeFantasia+" - Itens Adicionais - Export";
     // nomeArquivo = nomeArquivo.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
     // console.log("nomeArquivo", nomeArquivo);
      exportXlsxItensAdicionais(concessionariaSelected.value,nomeArquivo);
    }

    function baixarRelatorio() {
      if (showExportPdf.value) {
        exportBlob(contentResponse.value);
      } else {
        emitter.emit("geraXLSX");
      }
      reset();
    }

    async function getDescricoes() {
      if (codigosDescricao.value) return;
      try {
        const response = await getDescricao(concessionariaSelected.value);
        codigosDescricao.value = response.descricao.sort((a, b) => {
          if (a.codPersonalizacao > b.codPersonalizacao) return 1;
          if (a.codPersonalizacao < b.codPersonalizacao) return -1;
          return 0;
        });
      } catch (error) {
        console.log(error);
        showTimeAlert("Não foi possível completar a solicitação", "error");
      }
    }

    const filterItemsDescricao = computed(() => {
      if (searchDescrição.value) {
        return codigosDescricao.value?.filter((item: CodigosDescricao) => {
          return (
            item.nomePersonalizacao
              .toLowerCase()
              .includes(searchDescrição.value.toLowerCase()) ||
            item.codPersonalizacao.toString().includes(searchDescrição.value)
          );
        });
      } else {
        return codigosDescricao.value;
      }
    });

    const buttonShowModalDescricao = () => {
      modalCodigosDescricao.value.show();
      searchDescrição.value = "";
    };

    onMounted(async () => {
            //modal codigos descricao
      console.log("codConcessionaria",route.params.codConcessionaria);

      concessionariaSelected.value = Number(route.params.codConcessionaria.toString());
      await window.localStorage.setItem("filtroConcessionaria",route.params.codConcessionaria.toString() )
      const modalDescricao = document.getElementById("modalDescricao");
      if (modalDescricao) {
        document.getElementById("layout_content")?.appendChild(modalDescricao);
      }

      modalCodigosDescricao.value = new Modal(modalDescricao);
    });

    const downloadModeloRelatorio = () => {
     // console.log("preciso criar o modelo");
      var nomeArquivo = "ItensAdicionaisModeloKit.xlsx";
      console.log("nomeArquivo", nomeArquivo);
     // nomeArquivo = nomeArquivo.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      exportXlsxItensAdicionaisModeloKit(nomeArquivo);
   
    };



    return {
      showExportXLSX,
      showExportPdf,
      contentEmail,
      modalShareEmail,
      loadingEmail,
      reset,
      loadingExportPdf,
      loadingExportXLSX,
      disabledButtonExportar,
      showClose,
      modalExport,
      baixarRelatorio,
      exportarRelatorio,
      contentResponse,
      exportarItensAdicionais,
      importExcelView,
      handleSuccess,
      uploadRef,
      downloadModeloRelatorio,
      modalCodigosDescricao,
      searchDescrição,
      buttonShowModalDescricao,
      filterItemsDescricao,
      showImportView,
    };
  },
};
